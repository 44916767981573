import React, { useCallback } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { fg } from '@confluence/feature-gating';
import { withFlags } from '@confluence/flags';
import { useSidePanelStateActions } from '@confluence/side-panel-state-container';

import { IssueCreateWarningDialog } from './IssueCreateWarningDialog';
import { IssueCreateSidePanelFooter } from './IssueCreateSidePanelFooter';
import { IssueCreateSidePanelBody } from './IssueCreateSidePanelBody';
import { IssueCreateSidePanelHeader } from './IssueCreateSidePanelHeader';
import type { IssueCreateSidePanelProps } from './__types__/IssueCreateSidePanel';
import { IssueCreateAiLoadingSidebar } from './IssueCreateAiLoadingSidebar';
import {
	useAiIssueCreationEnablementState,
	useIssueCreateSidePanelContext,
} from './providers/IssueCreateSidePanelContextProvider';
import { SingleIssueCreateProvidersWrapper } from './providers/SingleIssueCreateProvidersWrapper';
import { SidePanelWrapper } from './SidePanelWrapper';
import { useGetWarningDialogActions } from './utils/useGetWarningDialogActions';

export const sidePanelTestId = 'issue-create-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_ID = 'issue-create-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_WIDTH = 320;

const sidePanelStyle = xcss({
	position: 'relative',
});

const IssueCreateSidePanelComponent = ({ onClose, flags }: IssueCreateSidePanelProps) => {
	const { showSidePanel, hideSidePanel } = useSidePanelStateActions();
	const { isWarningDialogOpen, handleConfirm, handleCancel } = useGetWarningDialogActions(onClose);

	const [state, actions] = useIssueCreateSidePanelContext();
	const { aiState, isCreatedWithAi, aiAnalyticsContext } = state;
	const aiIssueCreationEnablement = useAiIssueCreationEnablementState();
	const { trackAIInteractionDismiss } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldShowAiFooter = isCreatedWithAi && aiState !== 'error';

	const isAiLoading = aiState === 'loading';
	const handleOnClose = useCallback(
		(isCreateSuccess?: boolean) => {
			if (isCreatedWithAi && !isCreateSuccess) {
				trackAIInteractionDismiss({ attributes: aiAnalyticsContext });
			}

			if (!isCreateSuccess) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: 'confluenceHighlightIssueCreate',
						action: 'closed',
						actionSubject: 'modalDialog',
						actionSubjectId: 'linkCreate',
						type: 'ui',
						attributes: {
							isManuallyCreated: !isCreatedWithAi,
							isCreatedWithAi,
							isAiLoading,
							aiEnablement: aiIssueCreationEnablement?.enablement,
						},
					},
				}).fire();
			}

			hideSidePanel();
			onClose();
			void actions.setAiState('idle');
		},
		[
			hideSidePanel,
			onClose,
			isCreatedWithAi,
			isAiLoading,
			trackAIInteractionDismiss,
			createAnalyticsEvent,
			actions,
			aiAnalyticsContext,
			aiIssueCreationEnablement?.enablement,
		],
	);

	const handleOnConfirm = useCallback(() => {
		if (fg('jira_ai_issue_create_force_idle_state_on_discard')) {
			void actions.setAiState('idle');
		}
		handleConfirm();
	}, [actions, handleConfirm]);

	showSidePanel();

	return (
		<Box testId={sidePanelTestId} xcss={sidePanelStyle}>
			<SidePanelWrapper>
				<IssueCreateAiLoadingSidebar>
					<IssueCreateSidePanelHeader onClose={handleOnClose} />
					<IssueCreateSidePanelBody onClose={handleOnClose} flags={flags} />
					{shouldShowAiFooter && <IssueCreateSidePanelFooter />}
				</IssueCreateAiLoadingSidebar>
			</SidePanelWrapper>
			{isWarningDialogOpen && (
				<IssueCreateWarningDialog
					onCancel={handleCancel}
					onConfirm={handleOnConfirm}
					issuesCount={1}
				/>
			)}
		</Box>
	);
};

const IssueCreateSidePanelComponentWithProvider = ({
	onClose,
	flags,
}: IssueCreateSidePanelProps) => {
	return (
		<SingleIssueCreateProvidersWrapper>
			<AIEventsInstrumentationProvider
				config={{
					// Required configruation
					product: 'confluence',
					subproduct: 'jira',
					aiFeatureName: 'confluenceAiWorkCreation',
					proactiveGeneratedAI: 0,
					userGeneratedAI: 1,
					// Optional configuration
					source: 'confluenceHighlightIssueCreate',
					skipAISessionValidation: true,
				}}
			>
				<IssueCreateSidePanelComponent onClose={onClose} flags={flags} />
			</AIEventsInstrumentationProvider>
		</SingleIssueCreateProvidersWrapper>
	);
};

export const IssueCreateSidePanel = withFlags(IssueCreateSidePanelComponentWithProvider);
