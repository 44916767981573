import { useMemo } from 'react';

import { ConvoAIErrorMessage, type ConvoAIStreamError } from '@atlassian/ai-agent-streaming';
import type { HookProps } from '@atlassian/ai-work-suggestion/types';

import { useAiIssueCreateAnalytics } from '../utils/useAiIssueCreateAnalytics';

export const isWhitelistedSLOError = (error: ConvoAIStreamError) => {
	const whitelistedSLOErrors: Array<ConvoAIErrorMessage> = [
		ConvoAIErrorMessage.ACCEPTABLE_USE_VIOLATIONS,
		ConvoAIErrorMessage.NOT_ENOUGH_CONTENT_ERROR,
		ConvoAIErrorMessage.RATE_LIMIT,
		ConvoAIErrorMessage.ANSWER_UNAVAILABLE_FOR_CONTENT,
		ConvoAIErrorMessage.NOT_FOUND,
	];
	return whitelistedSLOErrors.includes(error.message.message_template as ConvoAIErrorMessage);
};

export const useStreamingHookProps = ({
	confluencePage,
	highlightedText,
	overrideXProduct,
}: {
	confluencePage: string;
	highlightedText: string;
	overrideXProduct?: string;
}) => {
	const { fireTrack } = useAiIssueCreateAnalytics();
	return useMemo(
		() => ({
			analyticsConfig: {
				aiFeatureName: 'confluenceAiWorkCreation',
				product: 'confluence',
				subproduct: 'jira',
				useDefaultGASv3EventSchema: false,
				source: 'highlightActionsMenu',
				skipAISessionValidation: true,
			},
			requestHeaderOverrides: {
				overrideXProduct,
			},
			issueConfig: {
				max_issues: 1,
				suggested_issue_field_types: [
					{
						issue_type: 'Task',
						fields: [
							{
								field_name: 'Summary',
								field_type: 'Short-Text',
							},
							{
								field_name: 'Description',
								field_type: 'Paragraph',
							},
						],
					},
				],
			},
			experienceId: 'ai-issue-create-confluence',
			sourceType: 'documentation',
			input: {
				source: 'CONFLUENCE',
				context: {
					primary_text: confluencePage,
					highlighted_text: highlightedText,
				},
			},
			onCompletion: () => {},
			customExperienceTracker: {
				start: () => {
					fireTrack('aiIssueCreateFetchSuggestedFieldsRequest start');
				},
				succeed: () => {
					fireTrack('aiIssueCreateFetchSuggestedFieldsRequest complete', {
						haveResult: true,
					});
				},
				fail: (convoAIStreamError: ConvoAIStreamError) => {
					fireTrack('aiIssueCreateFetchSuggestedFieldsRequest fail', {
						errorMessage: convoAIStreamError?.message?.content,
						statusCode: convoAIStreamError?.message?.status_code,
						message_template: convoAIStreamError?.message?.message_template,
						shouldSlo: !isWhitelistedSLOError(convoAIStreamError),
					});
				},
				abort: () => {
					fireTrack('aiIssueCreateFetchSuggestedFieldsRequest abort');
				},
			},
		}),
		[confluencePage, highlightedText, overrideXProduct, fireTrack],
	) as HookProps;
};
